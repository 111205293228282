import React from "react";

function CoursePrice({ price }) {
  return (
    <>
      <div className="text-red-600 font-bold">Hinta</div>

      {price ? (
        <span name="event-price">{price} € + alv</span>
      ) : (
        "Pyydä tarjous"
      )}
    </>
  );
}

export default CoursePrice;
