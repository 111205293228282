import React from "react";
import map from "lodash/map";
import filter from "lodash/filter";
import moment from "moment";

function CourseDates({ courseDates }) {
  // Filter past dates
  const now = moment();
  const filtered = filter(courseDates, ({ startDate }) => {
    return moment(startDate, "D.M.YYYY").isAfter(now);
  });

  return (
    <>
      <div className="text-red-600 font-bold">Ajankohdat</div>

      {filtered.length ? (
        <div>
          {map(filtered, ({ startDate, endDate, place }) => {
            let date =
              startDate && endDate
                ? `${startDate.replace(/\d+$/g, "")}-${endDate}`
                : startDate;
            if (startDate && endDate) {
            }

            return (
              <div key={date} name="event-date-and-place">
                {date}, {place}
              </div>
            );
          })}
        </div>
      ) : (
        <span>Kysy seuraavaa toteutusta</span>
      )}
    </>
  );
}

export default CourseDates;
