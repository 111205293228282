import React from "react";

function CourseDuration({ durationDays = 0 }) {
  return (
    <>
      <div className="text-red-600 font-bold">Kesto</div>
      {durationDays} {durationDays < 2 ? "päivä" : "päivää"}
    </>
  );
}

export default CourseDuration;
